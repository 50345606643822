import React, { forwardRef, useState, useRef, useEffect } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import { ParallaxBanner } from 'react-scroll-parallax';
import { motion } from "framer-motion"
import Accordion from './Accordion';
import '../assets/stylesheets/About.scss';
import hat1 from '../assets/images/hats/hat1.webp';
import hat2 from '../assets/images/hats/hat2.webp';
import hat3 from '../assets/images/hats/hat3.webp';
import hat4 from '../assets/images/hats/hat4.webp';
import hat5 from '../assets/images/hats/hat5.webp';
import hat6 from '../assets/images/hats/hat6.webp';
import hat7 from '../assets/images/hats/hat7.webp';
import hat8 from '../assets/images/hats/hat8.webp';
import hat9 from '../assets/images/hats/hat9.webp';
import hat10 from '../assets/images/hats/hat10.webp';
import click from '../assets/images/hats/click.webp';
import tap from '../assets/images/hats/tap.webp';

const hatImages = [hat1, hat2, hat3, hat4, hat5, hat6, hat7, hat8, hat9, hat10];

const About = forwardRef(({meImage, onViewPortfolioClick}, ref) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const clickRef = useRef();
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useEffect(() => {
    setIsTouchDevice(('ontouchstart' in window) || navigator.maxTouchPoints > 0);
  }, []);

  const handleHatClick = (event) => {
    event.stopPropagation();
    clickRef.current.style.opacity = 0;
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % hatImages.length);
  };

  return (
    <ParallaxProvider>
      <section id="about">
        <div id="container-about-a">
          <div className="container-header">
            <motion.div
              initial={{ y: "100%" }}
              animate={{ y: "0%" }}
              transition={{ duration: 1, delay: 0.5}}>
              <h1 className="header-lg text-white">About Me</h1>
            </motion.div>
          </div>
          <div id="container-about-a-info">
            <ParallaxBanner
              layers={[{ image: meImage, speed: -10 }]}
              id="img-about-me"
            />
            <div>
            <p className="text-body text-white">
              Hey, I’m Emilia—a Mexican-American software engineer, UX/UI designer, and creative thinker based in Barcelona.  
              I specialize in building scalable, intuitive, and visually compelling digital experiences. Whether it’s crafting elegant UIs, architecting robust back-end systems, or designing brand identities, I thrive at the intersection of technology and design.  
              <br /><br />
              My expertise spans the entire product lifecycle—from conceptualization and prototyping to full-scale development and deployment. I blend engineering precision with a designer’s eye to create solutions that are not just functional, but also seamless and user-friendly.  
              <br /><br />
              I’ve worked with startups finding their footing, established companies pushing innovation, and independent clients bringing bold ideas to life. No matter the project, my goal is always the same: to turn complex problems into elegant, efficient solutions.  
              <br /><br />
              When I’m not coding or designing, you’ll find me painting, traveling, working out, or hanging out with my friends, family, and my dog.
            </p>
            </div>
          </div>
          <div className="container-header">
            {/* <motion.div
              initial={{ y: "100%" }}
              whileInView={{ y: "0%" }}
              viewport={{ once: true }}
              transition={{ duration: 1, delay: 0.5}}> */}
              <h2 className="header-md text-white">Skills</h2>
            {/* </motion.div> */}
          </div>
          <div id="container-about-a-skills">
          <Accordion title="Software Engineering & Architecture">
            <p className="text-body-sm text-white">
              I design and build scalable, maintainable, and high-performance applications that align with both technical and business needs. 
              My work focuses on software architecture, engineering best practices, and performance optimization.
            </p>

            <p className="text-body-sm text-white">Key Areas:</p>
            <ul className="text-body-sm text-white">
              <li>Scalability – Architecting systems that handle growth efficiently.</li>
              <li>Performance – Optimizing speed, caching strategies, and database tuning.</li>
              <li>System Design – Microservices, monolithic structures, and layered architecture.</li>
              <li>Security – Implementing authentication, authorization, and secure coding.</li>
              <li>Best Practices – SOLID principles, clean code, and maintainability.</li>
            </ul>

            <p className="text-body-sm text-white">Technologies & Tools:</p>
            <ul className="text-body-sm text-white">
              <li>Architecture Patterns – Event-driven, MVC.</li>
              <li>APIs – REST, GraphQL, WebSockets.</li>
              <li>Observability – Logging, monitoring (New Relic), and tracing.</li>
            </ul>
          </Accordion>

          <Accordion title="Full-Stack Development">
            <p className="text-body-sm text-white">
              I develop applications from concept to deployment, ensuring a seamless integration between front-end interactivity and back-end performance.
            </p>

            <p className="text-body-sm text-white">Technologies I Work With:</p>
            <ul className="text-body-sm text-white">
              <li>Languages – JavaScript, TypeScript, Ruby, Java, Python.</li>
              <li>Front-End – React, Redux, Next.js, Stimulus, Tailwind, Sass.</li>
              <li>Back-End – Ruby on Rails, Spring Boot.</li>
              <li>Databases – PostgreSQL, MongoDB.</li>
              <li>Background Jobs – Sidekiq for asynchronous processing and job queuing.</li>
            </ul>
          </Accordion>

          <Accordion title="Testing & Quality Assurance">
            <p className="text-body-sm text-white">
              I implement robust testing strategies to maintain reliability, prevent regressions, and ensure software stability.
            </p>

            <p className="text-body-sm text-white">Testing Strategies:</p>
            <ul className="text-body-sm text-white">
              <li>Unit Testing – Jest, Mocha, Chai, RSpec, PyTest.</li>
              <li>Integration Testing – Ensuring components interact correctly.</li>
              <li>End-to-End Testing – Playwright.</li>
              <li>Continuous Testing – Automated testing in CI/CD pipelines.</li>
            </ul>
          </Accordion>

          <Accordion title="DevOps & Deployment">
            <p className="text-body-sm text-white">
              My workflow integrates DevOps principles to streamline deployments, infrastructure automation, and system reliability.
            </p>

            <p className="text-body-sm text-white">Technologies & Tools:</p>
            <ul className="text-body-sm text-white">
              <li>Version Control – Git, GitHub, GitLab.</li>
              <li>CI/CD Pipelines – GitHub Actions.</li>
              <li>Containerization & Orchestration – Docker, Kubernetes.</li>
              <li>Cloud & Hosting – AWS, Heroku, Netlify, Vercel.</li>
            </ul>
          </Accordion>

          <Accordion title="UX / UI Design">
            <p className="text-body-sm text-white">
              I craft user-centric experiences by combining design, usability, and accessibility principles.
            </p>

            <p className="text-body-sm text-white">Design Process:</p>
            <ul className="text-body-sm text-white">
              <li>User Research – Understanding user needs and pain points.</li>
              <li>Wireframing & Prototyping – Creating interactive mockups in Figma.</li>
              <li>Visual Design – Developing intuitive and aesthetically pleasing interfaces.</li>
              <li>Usability Testing – Refining interactions based on feedback.</li>
            </ul>

            <p className="text-body-sm text-white">Tools I Use:</p>
            <ul className="text-body-sm text-white">
              <li>Figma – Wireframing, prototyping, and UI design.</li>
              <li>Adobe Illustrator – Branding and vector graphics.</li>
              <li>Procreate – Digital illustrations and creative concepts.</li>
            </ul>
          </Accordion>

          <Accordion title="Graphic Design">
            <p className="text-body-sm text-white">
              I create digital and branding assets that combine creativity with strategic design.
            </p>

            <p className="text-body-sm text-white">What I Create:</p>
            <ul className="text-body-sm text-white">
              <li>Logo & brand identity.</li>
              <li>Marketing materials & social media assets.</li>
              <li>Custom digital illustrations.</li>
            </ul>

            <p className="text-body-sm text-white">Design Tools:</p>
            <ul className="text-body-sm text-white">
              <li>Adobe Illustrator – Logos, branding, and vector graphics.</li>
              <li>Procreate – Hand-drawn illustrations.</li>
            </ul>
          </Accordion>
          </div>
        </div>
        <div id="container-about-b" ref={ref}>
          <div id="container-about-b-info">
              <h2 className="header-md text-white">As a designer & developer I wear many <span style={{textWrap: "nowrap"}}><span className="text-hollow">hats</span>.</span></h2>
            <div id="container-about-b-info-btn">
              <button className="btn-white-rounded" onClick={onViewPortfolioClick}>View Portfolio</button>
            </div>
          </div>
          <div id="container-about-b-hats">
            <img src={isTouchDevice ? tap : click} alt={isTouchDevice ? "Tap" : "Click"} id="img-click" ref={clickRef} />
            <img
              src={hatImages[currentImageIndex]}
              alt="About Me"
              onClick={handleHatClick}
            />
        </div>
        </div>
      </section>
    </ParallaxProvider>
  );
});

export default About;
